<form (ngSubmit)="submit()" [formGroup]="form" nz-form>
  <nz-form-item>
    <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="email" nzRequired>{{'Email_enter' | translate}}</nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24">
      <input formControlName="email" id="email" nz-input type="email">
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="password" nzRequired>{{'Password' | translate}}</nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24">
      <input formControlName="password" id="password" nz-input type="password">
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="confirmPassword" nzRequired>{{'Password_Confirmation' | translate}}
    </nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24">
      <input formControlName="confirmPassword" id="confirmPassword" nz-input type="password">
    </nz-form-control>
  </nz-form-item>
  <nz-form-item nz-row>
    <nz-form-control [nzOffset]="6" [nzSpan]="14">
      <button [disabled]="!form.valid" nz-button nzType="primary" type="submit">{{'Submit' | translate}}</button>
    </nz-form-control>
  </nz-form-item>
</form>
@if (errorMessageCode !== undefined) {
  <nz-alert nzMessage="{{('AUTH_ERRORS.'+errorMessageCode) | translate}}"
  nzType="error"></nz-alert>
}
<nz-divider [nzText]="'Or' | translate"></nz-divider>
<div class="oauth-buttons">
  <button (click)="googleOauth()" nz-button
  nzType="primary">{{'Google_register' | translate}}</button>
</div>
